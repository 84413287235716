<template>
  <div class="tarifrechner">
    <b-row>
      <b-col cols="2">
        <EnergieartSchalter v-model="preisabfrageRequestForm.produkttyp"></EnergieartSchalter>
      </b-col>
      <b-col cols="2">
        <KundentypSchalter v-model="preisabfrageRequestForm.kundentyp"></KundentypSchalter>
      </b-col>
      <b-col cols="2">
        <b-input-group prepend="PLZ">
          <b-form-input v-model="preisabfrageRequestForm.plz" placeholder="PLZ"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="6">
        <b-button-toolbar>
          <b-button-group v-if="preisabfrageRequestForm.kundentyp === Kundentyp.Privat">
            <b-button size="sm" v-for="(icons, verbrauch) in verbraeuche" :key="verbrauch"
                      :pressed="preisabfrageRequestForm.verbrauch === verbrauch"
                      variant="fc-gray-light" @click="preisabfrageRequestForm.verbrauch=verbrauch">
              <b-icon v-for="(icon, key) in icons" :key="key" :icon="icon"></b-icon>
            </b-button>
          </b-button-group>
          <b-input-group :prepend="preisabfrageRequestForm.kundentyp === 'GK' ? 'Verbrauch' : null" append="kWh">
            <b-form-input v-model="preisabfrageRequestForm.verbrauch" class="text-right"></b-form-input>
          </b-input-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="pt-2 pb-lg-4">
        <b-button block @click="getPreise()" variant="primary" :disabled="submitEnabled === false">
          <span v-if="submitEnabled === true">Suchen</span>
          <b-spinner label="Loading" class="mx-2" v-if="submitEnabled === false" small></b-spinner>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="preise !== null && preise.length === 0">
      <b-col>
        <p>Keine Preise verfügbar</p>
      </b-col>
    </b-row>
    <b-row v-if="preise !== null && preise.length > 0">
      <b-col>
        <p>{{ preise.length }} {{produkttypFuerErgebnisliste}} {{Kundentypen[kundentypFuerErgebnisliste]}} Ergebnisse für PLZ {{ plzFuerErgebnisliste }} bei {{ verbrauchFuerErgebnisliste }} kWh Verbrauch</p>
      </b-col>
    </b-row>
    <b-row v-if="preise !== null && preise.length > 0">
      <b-col>
        <b-table striped hover :items="tableItems" :fields="tableFields" sort-by="produktname" sort-icon-left responsive="md">
          <template v-slot:cell(produktname)="data">
            <img src="../../../common/assets/vattenfall_logo.png" alt="Vattenfall" height="20" class="mb-2"/><br>
            {{ data.value }}
          </template>
          <template v-slot:cell(grundpreis)="data">
            {{ (data.value / 12).toLocaleString('de-DE', localeStringCurrencyOptions) }} monatlich<br>
            {{ data.value.toLocaleString('de-DE', localeStringCurrencyOptions) }} im Jahr
          </template>
        </b-table>
        <p v-if="preisabfrageRequestForm.kundentyp === 'PK'">Preise sind brutto.</p>
        <p v-if="preisabfrageRequestForm.kundentyp === 'GK'">Preise sind netto.</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import KundentypSchalter from '../../../common/components/KundentypSchalter'
  import PreisabfrageRequest from '../../../common/model/request/PreisabfrageRequest'
  import PreisService from '../../../common/api/public/PreisService'
  import Preis from '../../../common/model/domain/Preis'

  import {Kundentyp, Kundentypen, Energieart, Energiearten} from '../../../common/constants'

  import Vue from 'vue'
  import {
    ButtonGroupPlugin,
    ButtonToolbarPlugin,
    FormInputPlugin,
    IconsPlugin,
    InputGroupPlugin,
    SpinnerPlugin,
    TablePlugin
  } from 'bootstrap-vue'
  import EnergieartSchalter from '../../../common/components/EnergieartSchalter'

  Vue.use(ButtonGroupPlugin)
  Vue.use(ButtonToolbarPlugin)
  Vue.use(FormInputPlugin)
  Vue.use(IconsPlugin)
  Vue.use(InputGroupPlugin)
  Vue.use(SpinnerPlugin)
  Vue.use(TablePlugin)

  export default {
    name: 'Tarifrechner',
    components: {
      EnergieartSchalter,
      KundentypSchalter
    },
    data: function () {
      return {
        /** @var {Preis[]|null} preise */
        preise: null,
        Kundentyp,
        Kundentypen,
        Energiearten,
        verbrauchFuerErstesJahr: 2000,
        plzFuerErgebnisliste: null,
        kundentypFuerErgebnisliste: null,
        produkttypFuerErgebnisliste: null,
        verbrauchFuerErgebnisliste: null,
        verbraeuche: {
          '1500': ['person-fill'],
          '2000': ['person-fill', 'person-fill'],
          '3000': ['person-fill', 'person-fill', 'person-fill'],
          '4000': ['person-fill', 'person-fill', 'person-fill', 'person-fill']
        },
        submitEnabled: true,
        localeStringCurrencyOptions: {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
          minimumFractionDigits: 2
        },
        tableFields: [
          {
            key: 'produktname',
            label: 'Tarif',
            sortable: true
          },
          {
            key: 'grundpreis',
            label: 'Grundpreis',
            formatter: value => {
              return (value ? value : 0)
            },
            sortable: true
          },
          {
            key: 'arbeitspreis',
            label: 'Arbeitspreis',
            formatter: value => {
              return (value ? value : 0).toLocaleString('de-DE', {
                style: 'decimal',
                minimumFractionDigits: 2
              }) + ' ct/kWh'
            },
            sortable: true
          },
          {
            key: 'bonus',
            label: 'Bonus',
            formatter: (value, key, item) => {
              let bonus = (item.bonus ? item.bonus : 0) + (item.willkommensbonus ? item.willkommensbonus : 0) + (item.bleibebonus ? item.bleibebonus : 0)
              return bonus.toLocaleString('de-DE', this.localeStringCurrencyOptions)
            },
            sortable: true
          },
          {
            // A virtual column with custom formatter
            key: 'firstYear',
            label: '1. Jahr',
            formatter: value => {
              return value.toLocaleString('de-DE', this.localeStringCurrencyOptions)
            },
            sortable: true
          }
        ],
        preisabfrageRequestForm: {
          produkttyp: Energieart.Strom,
          kundentyp: Kundentyp.Privat,
          plz: '',
          verbrauch: '2000',
          stichtag: new Date()
        }
      }
    },
    computed: {
      tableItems: function() {
        if (this.preise === null) {
          return null;
        }
        return this.preise.map((p) => {
          let bonus = (p.bonus ? p.bonus : 0) + (p.willkommensbonus ? p.willkommensbonus : 0) + (p.bleibebonus ? p.bleibebonus : 0)
          p.firstYear = p.grundpreis + (this.verbrauchFuerErstesJahr * p.arbeitspreis / 100) - bonus
          return p
        })
      }
    },
    methods: {
      getPreise() {
        this.submitEnabled = false
        this.verbrauchFuerErstesJahr = this.preisabfrageRequestForm.verbrauch
        this.plzFuerErgebnisliste = this.preisabfrageRequestForm.plz
        this.kundentypFuerErgebnisliste = this.preisabfrageRequestForm.kundentyp
        this.produkttypFuerErgebnisliste = this.Energiearten[this.preisabfrageRequestForm.produkttyp]
        this.verbrauchFuerErgebnisliste = this.preisabfrageRequestForm.verbrauch
        PreisService.getList(new PreisabfrageRequest(this.preisabfrageRequestForm))
          .then(response => {
            this.preise = response.data.map(p => new Preis(p))
            this.submitEnabled = true
          })
          .catch(() => {
            this.submitEnabled = true
          })
      }
    }
  }
</script>
