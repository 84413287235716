class Preis {
  /**
   * @param {{ id:Number, plz:String, ort:String, staffelVon:Number, staffelBis:Number, gueltigSeit:String,
   * grundpreis:Number, arbeitspreis:Number, arbeitspreisNT:Number, bonus:Number, willkommensbonus:Number,
   * bleibebonus:Number, vpCode:String, netzbetreiber:String, vertriebsgebiet:String, preisId:String,
   * produktId:String, bonusId:String, messpreisG25Euro:String, messpreisG40Euro:String, messpreisG60Euro:String,
   * messpreisG65Euro:String, messpreisG100Euro:String, messpreisG160Euro:String, messpreisG250Euro:String,
   * messpreisG400Euro:String, gueltigBis:String, produkttyp:String, kundentyp:String, produktname:String, tarifId:String }} preisData
   */
  constructor(preisData) {
    this.id = preisData.id
    this.plz = preisData.plz
    this.ort = preisData.ort
    this.staffelVon = preisData.staffelVon
    this.staffelBis = preisData.staffelBis
    this.gueltigSeit = preisData.gueltigSeit
    this.grundpreis = preisData.grundpreis
    this.arbeitspreis = preisData.arbeitspreis
    this.arbeitspreisNT = preisData.arbeitspreisNT
    this.bonus = preisData.bonus
    this.willkommensbonus = preisData.willkommensbonus
    this.bleibebonus = preisData.bleibebonus
    this.vpCode = preisData.vpCode
    this.netzbetreiber = preisData.netzbetreiber
    this.vertriebsgebiet = preisData.vertriebsgebiet
    this.preisId = preisData.preisId
    this.produktId = preisData.produktId
    this.bonusId = preisData.bonusId
    this.messpreisG25Euro = preisData.messpreisG25Euro
    this.messpreisG40Euro = preisData.messpreisG40Euro
    this.messpreisG60Euro = preisData.messpreisG60Euro
    this.messpreisG65Euro = preisData.messpreisG65Euro
    this.messpreisG100Euro = preisData.messpreisG100Euro
    this.messpreisG160Euro = preisData.messpreisG160Euro
    this.messpreisG250Euro = preisData.messpreisG250Euro
    this.messpreisG400Euro = preisData.messpreisG400Euro
    this.gueltigBis = preisData.gueltigBis
    this.produkttyp = preisData.produkttyp
    this.kundentyp = preisData.kundentyp
    this.produktname = preisData.produktname
    this.tarifId = preisData.tarifId
  }
}
export default Preis
